
import { defineComponent, reactive, toRefs, getCurrentInstance, ComponentInternalInstance, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { resume } from '@/views/my-management/config'
import { ZMessage, ZConfirm } from '@/utils/confirm'
import cryptojs from '@/utils/sha1.js'
import { util } from '@/utils/util.js'
import getCity from '@/components/getCity/h5.vue'

export default defineComponent({
  components: {
    getCity
  },

  setup() {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loading = proxy.$loading

    const state: any = reactive({
      path: route.query.path,
      isFreeLogin: null,
      resumeType: null,
      index: null,
      formOps: [],
      item: {},
      showPicker: false, // 显示键盘
      keyboardType: null, // 键盘类型
      DateKeyboardValue: new Date(), // 日期选择键盘默认时间
      SelectKeyboardIndex: 0,
      minDate: new Date('1950/01/01'),
      nowDate: new Date(),
      maxDate: new Date('2051/01/01'),
      fields: null,
      // select选项
      options: [],
      avatar: [], //防止上传头像那报错 ，同时可以进行表单验证做的
      showProvince: false, //控制getCity组件
      familyMemberList: [],
      familyConfig: [
        { label: '亲属姓名', value: 'familyName', formType: 'provinceCity', span: 7, isRequire: true },
        {
          label: '亲属性别',
          value: 'sexKey',
          formType: 'radio'
        },
        { label: '亲属联系方式', value: 'familyPhone', width: '100px', span: 7, isRequire: true }
      ]
    })

    state.resumeType = store.state.resumeType
    state.isFreeLogin = store.state.isFreeLogin

    /**
     * @description : 判断是新增还是修改
     * @result : index 空-新增 非空-修改
     */
    const judgeAddOrUpdate = () => {
      if (route.query.index !== null && route.query.index !== undefined) {
        state.index = route.query.index
      }
    }
    judgeAddOrUpdate()

    const handleTakeOffice = (item, r, val) => {
      state.item[r.value] = val
      if (item.familyMemberVoList.length === 0) {
        addNewFamily()
      }
    }

    /**
     * @description : 获取form数据
     * @result : datePickerRange处理
     */
    const getFormOps = () => {
      if (state.path && state.path == 'selfEvaluation') {
        state.formOps = [{ label: '自我评价', value: 'selfEvaluation', formType: 'textarea' }]
      } else {
        if (state.path && resume[state.path] && resume[state.path].children) {
          resume[state.path].children.forEach((v) => {
            if (v.formType == 'datePickerRange') {
              state.formOps.push(
                { label: '开始时间', value: 'startTime', formType: 'datePickerRange' },
                { label: '结束时间', value: 'endTime', formType: 'datePickerRange' }
              )
            } else {
              state.formOps.push(v)
            }
          })
        }
        // UI想把 上传头像坐在基础信息里
        if (state.path && state.path == 'base') {
          state.formOps.splice(0, 0, { label: '头像', value: 'photoUrl', formType: 'avatar', isRequire: false })
          if (!(state.resumeType === 1 && state.isFreeLogin)) {
            const index = state.formOps.findIndex((v) => v.value === 'isTakeOffice')
            state.formOps.splice(index, 1)
          }
        }
      }
    }
    getFormOps()

    onMounted(() => {
      getResumeItemInfo()
    })

    const handleRelativeRadio = (val, index) => {
      console.log('更新值', val)
      state.item.familyMemberVoList[index]['sexKey'] = val
    }

    const addNewFamily = () => {
      let params = {}
      state.familyConfig.forEach((el) => {
        params[el.value] = ''
      })
      console.log('啥时候运行的')
      state.item.familyMemberVoList.push(params)
      state.familyMemberList.push(params)
    }

    const deleteFamily = (index) => {
      state.item.familyMemberVoList.splice(index, 1)
      state.familyMemberList.slice(index, 1)
    }

    /**
     * 获取简历数据
     *
     * @param
     */
    const getResumeItemInfo = () => {
      console.log(`getResumeItemInfo`, store.state.resumeInfo)
      if (store.state.resumeInfo) {
        if (state.path && state.path == 'base') {
          state.item = JSON.parse(JSON.stringify(store.state.resumeInfo))
          // 年龄根据出生年月计算
          // 有出生日期 - 不可编辑
          // 无出生日期 - 可编辑
          if (state.item.birthday) {
            state.item.age = util.getAge(state.item.birthday.split('-'))
            state.formOps.forEach((v) => {
              if (v.value == 'age') {
                v.disabled = true
              }
            })
          }
          if (state.item.photoUrl) {
            state.avatar = [{ photoUrl: state.item.photoUrl }]
          }
          console.log('state', state.item.familyMemberVoList, store.state.resumeType)
          if (state.item.familyMemberVoList && state.item.familyMemberVoList.length > 0) {
            state.item.isTakeOffice = '1'
          } else {
            state.item.familyMemberVoList = []
          }
          if (state.item.isTakeOffice === 1) {
            state.item.isTakeOffice = '1'
          }
          if (state.item.isTakeOffice === 0) {
            state.item.isTakeOffice = '0'
          }
          // state.item.isTakeOffice = null
          state.familyMemberList = JSON.parse(JSON.stringify(state.item.familyMemberVoList))
        } else if (state.path && state.path == 'selfEvaluation') {
          state.item = {
            selfEvaluation: store.state.resumeInfo.selfEvaluation
          }
        } else if (state.path && state.path == 'personalWorksList') {
          if (state.path && state.index !== null) {
            state.item = JSON.parse(JSON.stringify(store.state.resumeInfo[state.path][+state.index]))
            if (state.item.worksType == 1) {
              state.item.fileList = [
                {
                  // name: state.item.attachmentSourceName,
                  attachmentName: state.item.attachmentName,
                  attachmentSourceName: state.item.attachmentSourceName,
                  attachmentUrl: state.item.attachmentUrl
                  // url: state.item.attachmentUrl
                }
              ]
            } else {
              state.item.fileList = []
            }
          } else {
            state.item.fileList = []
          }
        } else if (state.path) {
          if (state.index !== null) {
            state.item = JSON.parse(JSON.stringify(store.state.resumeInfo[state.path][+state.index]))
          }
          // 已有最高学历 - 其他 不可编辑
          // 没有最高学历 - 可编辑
          if (state.path == 'educationExperiences') {
            if (store.state.resumeInfo && store.state.resumeInfo.educationExperiences) {
              const hasHighEducation = store.state.resumeInfo.educationExperiences.some(
                (el) => el.educationTypeKey === '30'
              )
              if (hasHighEducation) {
                if (state.item.educationTypeKey == null) {
                  state.item.educationTypeKey = '40'
                  state.item.educationTypeValue = '其他'
                }
                state.formOps.forEach((v) => {
                  if (v.value == 'educationTypeValue' && state.item.educationTypeKey == '40') {
                    v.disabled = true
                  } else {
                    v.disabled = false
                  }
                })
              }
            }
          }
        }
      } else {
        router.go(-1)
      }
    }
    const clearFile = () => {
      state.item.fileList = []
    }
    /**
     * @description : 键盘弹起
     * @param : keyboardType SelectKeyboard-下拉 DateKeyboard-时间选择
     * @result : field 当前操作字段
     * @result : 键盘弹起
     */
    const getkeyboardType = (keyboardType, r) => {
      console.log('fdsfds', r)
      if (r.disabled) {
        return false
      }
      state.keyboardType = keyboardType
      state.fields = r
      state.showPicker = true
      if (keyboardType == 'SelectKeyboard') {
        getSelectOptions(r)
      }
      if (keyboardType === 'DateKeyboard') {
        if (state.item[r.value]) {
          if (state.item[r.value] == '至今') {
            state.DateKeyboardValue = new Date()
          } else {
            state.DateKeyboardValue = new Date(state.item[r.value])
          }
        } else {
          state.DateKeyboardValue = new Date()
        }
      }
      if (keyboardType === 'DateKeyboard' && r.value === 'birthday') {
        if (state.resumeType == 2 && !state.item['birthday']) {
          state.DateKeyboardValue = new Date('2000-01-01')
        }
      }
    }

    /**
     * @description : 键盘弹起
     * @param : keyboardType SelectKeyboard-下拉 DateKeyboard-时间选择
     * @result :
     */
    const handleSelectChange = (item) => {
      console.log(`handleSelectChange`, item, state)
      if (state.keyboardType == 'SelectKeyboard') {
        state.item[state.fields.value] = item.dataName
        state.item[state.fields.key] = item.dataCode
      }
      if (state.keyboardType == 'DateKeyboard') {
        state.item[state.fields.value] = proxy.$dayjs(state.DateKeyboardValue).format('YYYY-MM-DD')
        // 年龄根据出生年月计算
        if (state.fields.value == 'birthday' && state.item.birthday) {
          state.item.age = util.getAge(state.item.birthday.split('-'))
          state.formOps.forEach((v) => {
            if (v.value == 'age') {
              v.disabled = true
            }
          })
        }
      }
      state.showPicker = false
      // document.body.style.position = 'static'
    }

    /**
     * picker点击取消
     *
     *
     */
    const handlePopupClose = () => {
      state.showPicker = false
      // document.body.style.position = 'static'
    }

    /**
     * @description : 获取Select下拉基础数据
     * @description : resume前调用
     * @result : 最高学历 最高学位 学习形式 熟练程度
     *  这里vant-picker有个坑
     */
    const getSelectOptions = (r: any) => {
      console.log(`getSelectOptions`, r)
      if (!r.selectEnum.length) {
        const isNotFormPs = ['recruit_source', 'year_limit', 'certificate_type'].includes(r.selectKey)
        if (isNotFormPs) {
          proxy.$http.base.getDictionaryCascade({ groupName: r.selectKey }).then((res: any) => {
            if (res && res.length) {
              r.selectEnum = res.map((v) => {
                return {
                  dataCode: v.key,
                  dataName: v.value
                }
              })
              state.options = r.selectEnum
              if (state.item[r.key] && state.item[r.value]) {
                const findIndex = r.selectEnum.findIndex((v) => {
                  return v.dataCode === state.item[r.key]
                })
                state.SelectKeyboardIndex = findIndex
              } else {
                state.SelectKeyboardIndex = 0
              }
              console.log('getDictionaryCascade', state.item[r.key], state.item[r.value])
            }
          })
        } else {
          proxy.$http.base.queryKvList({ group: r.selectKey }).then((res: any) => {
            r.selectEnum = res.map((v) => {
              return {
                dataCode: v.dataCode,
                dataName: v.dataName
              }
            })
            state.options = r.selectEnum
            if (state.item[r.key] && state.item[r.value]) {
              const findIndex = r.selectEnum.findIndex((v) => {
                return v.dataCode === state.item[r.key]
              })
              state.SelectKeyboardIndex = findIndex
            } else {
              state.SelectKeyboardIndex = 0
            }
            console.log('queryKvList', state.item[r.key], state.item[r.value])
          })
        }
      } else {
        console.log('getSelectOptions', state.item[r.key], state.item[r.value])
        state.options = r.selectEnum
        // 处理config中selectEnum固定情况
        if (state.item[r.key] && state.item[r.value]) {
          const findIndex = r.selectEnum.findIndex((v) => {
            return v.dataCode === state.item[r.key]
          })
          state.SelectKeyboardIndex = findIndex
        }
      }

      // if (selectKey == 'LEARN_FORM') state.options = proxy.$Global.selectEnum.LEARN_FORM
      // if (selectKey == 'C_EDU_XUEWEI') state.options = proxy.$Global.selectEnum.C_EDU_XUEWEI
      // if (selectKey == 'LEVEL_KEY') state.options = proxy.$Global.selectEnum.LEVEL_KEY
    }

    /**
     * @description : 提交
     * @description : resume前调用
     * @result : 最高学历 最高学位 学习形式 熟练程度
     */
    const handleSubmit = () => {
      console.log('item', state.item)
      ZConfirm('确认保存？', 'warning')
        .then(() => {
          updateInfo()
        })
        .catch((item) => {
          console.log(item)
        })
    }

    const onFailed = (errorInfo) => {
      console.log(`onFailed`, errorInfo)
    }

    /**
     * @description : 删除
     * @description : resume前调用
     * @result : 最高学历 最高学位 学习形式 熟练程度
     */
    const handleDelete = () => {
      ZConfirm('确认删除？', 'warning').then(() => {
        if (state.index !== null) {
          store.state.resumeInfo[state.path].splice(state.index, 1)
        }
        router.go(-1)
      })
    }

    const updateInfo = () => {
      if (state.path && state.path == 'base') {
        store.state.resumeInfo = state.item
      } else if (state.path && state.path == 'selfEvaluation') {
        store.state.resumeInfo.selfEvaluation = state.item.selfEvaluation
      } else if (state.path && state.path == 'personalWorksList') {
        let item = {
          worksType: state.item.worksType,
          worksUrl: state.item.worksType == 2 ? state.item.worksUrl : null,
          attachmentName: state.item.worksType == 1 ? state.item.fileList[0].attachmentName : null,
          attachmentSourceName: state.item.worksType == 1 ? state.item.fileList[0].attachmentSourceName : null,
          attachmentUrl: state.item.worksType == 1 ? state.item.fileList[0].attachmentUrl : null,
          worksDescription: state.item.worksDescription
        }
        if (state.index !== null) {
          store.state.resumeInfo[state.path][state.index] = item
        } else {
          store.state.resumeInfo[state.path].push(item)
        }
      } else {
        if (state.index !== null) {
          store.state.resumeInfo[state.path][state.index] = state.item
        } else {
          store.state.resumeInfo[state.path].push(state.item)
        }
      }

      router.go(-1)
    }

    const beforeRead = (file) => {
      return true
    }
    const afterRead = (file, isAvatar) => {
      console.log(`afterRead`)
      loading.show('上传中...')
      proxy.$http.resume
        .getToken()
        .then((res: any) => {
          let time = new Date().getTime()
          let nonce = cryptojs.sha1(time).toString()
          let timestamp = Number(time / 1000)

          let uploads = {
            upload_token: res.uploadToken,
            file: file,
            group: 'private',
            appid: res.appId,
            nonce: nonce,
            signature: cryptojs.sha1([nonce, timestamp, res.uploadToken].sort().join('')).toString().toUpperCase(),
            timestamp: timestamp
          }
          uploadFile(uploads, isAvatar)
        })
        .catch(() => {
          state.item.fileList = []
          loading.hide()
        })
    }
    const handleAvatarRemove = () => {
      state.item.photoUrl = ''
      state.item.empPhoto = null
      state.avatar = []
    }

    // 上传文件到文件服务器
    const uploadFile = (uploads, isAvatar) => {
      let formData = new FormData()
      formData.append('appid', uploads.appid)
      formData.append('upload_token', uploads.upload_token)
      formData.append('group', uploads.group)
      formData.append('timestamp', uploads.timestamp)
      formData.append('nonce', uploads.nonce)
      formData.append('filename', util.guid())
      formData.append('signature', uploads.signature)
      formData.append('ext', uploads.file.file.name.substring(uploads.file.file.name.lastIndexOf('.') + 1))
      formData.append('uploadfile', uploads.file.file)
      console.log(isAvatar)

      if (isAvatar) {
        proxy.$http.resume
          .uploadEncryptFile(formData)
          .then((res: any) => {
            proxy.$http.resume
              .getFile({
                attachmentName: res.fileName
              })
              .then((url) => {
                state.item.photoUrl = url
                state.item.empPhoto = res.fileName
                state.avatar = [{ photoUrl: url }]
              })
          })
          .catch(() => {
            state.item.photoUrl = null
            state.avatar = []
          })
          .finally(() => {
            loading.hide()
          })
      } else {
        proxy.$http.resume
          .uploadEncryptFile(formData)
          .then((res: any) => {
            console.log('aaaaaaaaaaaa', res)

            state.item.fileList = [
              {
                attachmentSourceName: uploads.file.file.name,
                attachmentName: res.fileName,
                attachmentUrl: res.fileName
              }
            ]
          })
          .catch(() => {
            state.item.fileList = []
          })
          .finally(() => {
            loading.hide()
          })
      }
    }

    const handleInputRules = (r: any) => {
      let rules = {}
      if (r.isRequire !== false) {
        rules = {
          required: true,
          trigger: 'onChange',
          message: `${r.label}不能为空`
        }
      }
      if (r.value === 'phone' || r.value === 'familyPhone') {
        rules = {
          ...rules,
          ...{
            pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
            trigger: 'onChange',
            message: '手机号码格式不正确',
            validateEmpty: false
          }
        }
      }

      if (r.value === 'email') {
        rules = {
          ...rules,
          ...{
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z.]+$/,
            trigger: 'onChange',
            message: '邮箱格式不正确',
            validateEmpty: false
          }
        }
      }
      return [rules]
    }

    // 触发popUp
    const handleProvice = () => {
      state.showProvince = !state.showProvince
      console.log('1', state.showProvince)
    }
    // 关闭popup
    const handleProvinceClose = () => {
      // state.showProvice = !state.showProvice
      state.showProvince = !state.showProvince
      console.log('close')
    }
    // 完成期望工作地时的操作
    const handleProFinish = (val: { ids: string[]; values: string[] }) => {
      state.showProvince = false
      state.item.expectWorkCity = val.values.join('/')
      state.item.expectWorkCityId = val.ids.join('>')
      console.log('finish', val.ids, val.values)
      console.log(' state.item.expectWorkCity', state.item.expectWorkCity)
      // state.showProvince = !state.showProvince
      // state.showPicker = !state.showProvice
    }
    return {
      ...toRefs(state),
      getkeyboardType,
      handleSelectChange,
      handlePopupClose,
      handleSubmit,
      handleDelete,
      afterRead,
      beforeRead,
      clearFile,
      handleAvatarRemove,
      onFailed,
      handleInputRules,
      handleProvice,
      handleProvinceClose,
      handleProFinish,
      handleRelativeRadio,
      addNewFamily,
      deleteFamily,
      handleTakeOffice
    }
  }
})
