import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_cascader = _resolveComponent("van-cascader")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createBlock(_component_van_popup, {
    show: _ctx.isShow,
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isShow) = $event)),
    round: "",
    position: "bottom"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_cascader, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        title: "请选择期望工作地",
        value: "code",
        "field-names": _ctx.fieldNames,
        options: _ctx.options,
        onClose: _ctx.onClose,
        onFinish: _ctx.onFinish,
        onChange: _ctx.onChange
      }, null, 8, ["modelValue", "field-names", "options", "onClose", "onFinish", "onChange"])
    ]),
    _: 1
  }, 8, ["show"]))
}