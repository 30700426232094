
import { defineComponent, getCurrentInstance, onMounted, reactive, toRefs, ref, toRaw, watch } from 'vue'

export default defineComponent({
  props: {
    num: {
      type: Number,
      required: false
    },
    show: {
      type: Boolean,
      required: true
    },
    valueIds: {
      type: String,
      default: ''
    }
  },
  emits: ['callback', 'close', 'finish'],
  setup(props, { emit }) {
    const { proxy }: { proxy: any; refs: any } = getCurrentInstance()
    const { num, show, valueIds } = reactive(props)
    const state = reactive({
      regionTree: [],
      value: '',
      isShow: false,
      lock: false
    })
    const options = ref(null)
    const fieldNames = {
      text: 'name',
      value: 'code',
      children: 'children'
    }

    const handleNumber = (arr: any[], num): any[] => {
      let newArr: any[] = arr
      let n = num - 1
      newArr.forEach((el) => {
        if (n === 0) {
          el.children = null
        } else if (el.children) {
          handleNumber(el.children, n) //递归调用 传入子数组
        }
      })
      return newArr
    }

    const onChange = ({ value }) => {
      // if (value === options.value[0].code) {
      //   proxy.$http.base.getRegionInfoTreeList().then((res) => {
      //     console.log('1f', options)
      //     state.regionTree = res.children
      //     if (!num) {
      //       options.value = toRaw(state.regionTree)
      //     } else {
      //       options.value = toRaw(handleNumber(state.regionTree, num))
      //     }
      //   })
      // }
      // console.log('options.value', options.value)
      // console.log('state.value', state.value)
    }

    onMounted(() => {
      // state.value = 'tdfsafsa'
      proxy.$http.base.getRegionInfoTreeList().then((res) => {
        console.log('1f', options)
        state.regionTree = res.children
        if (!num) {
          options.value = toRaw(state.regionTree)
        } else {
          options.value = toRaw(handleNumber(state.regionTree, num))
        }
      })
    })

    watch([props], (value) => {
      console.log('watch', value)
      state.isShow = value[0].show
      if (!state.lock) {
        const expectWorkCityId = value[0].valueIds + ''
        state.value = expectWorkCityId.split('>')[1]
        state.lock = true
      }
      console.log('state.value', state.value)
    })
    const onFinish = ({ selectedOptions }) => {
      const ids = selectedOptions.map((el) => {
        return el.code
      })
      const values = selectedOptions.map((el) => {
        return el.name
      })
      state.value = selectedOptions
        .map((option) => {
          console.log('options', option)
          return option.name
        })
        .join('/')
      console.log('sataevaliue', state.value)
      emit('finish', { ids, values })
    }

    const onClose = () => {
      emit('close')
    }
    return {
      ...toRefs(state),
      options,
      fieldNames,
      onFinish,
      onClose,
      onChange
    }
  }
})
