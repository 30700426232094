
import { onMounted, defineComponent, getCurrentInstance, ComponentInternalInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Edit } from '@element-plus/icons'

interface DataPros {
  userInfo: any
}

export default defineComponent({
  components: { Edit },
  setup() {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const router = useRouter()
    const store = useStore()

    const state: DataPros = reactive({
      userInfo: {}
    })

    onMounted(() => {
      if (localStorage.getItem('Authorization')) {
        getResumeInfo()
      }
    })

    const handleMenuClick = (name: string) => {
      router.push({
        name
      })
    }

    const handleLoginOut = (name: string) => {
      localStorage.removeItem('Authorization')
      store.dispatch('setUserInfo', {})
      // store.dispatch('setResumeType', '')
      localStorage.removeItem('resumeType')
      router.push({
        path: '/'
      })
    }

    /**
     * @description : 获取简历信息！！！
     * @description : 注意异常处理
     */
    const getResumeInfo = () => {
      console.log('userInfo', state.userInfo, store.state)
      // 已登录
      proxy.$http.resume.getResumeInfo().then((res: any) => {
        state.userInfo = res
      })
    }

    return {
      ...toRefs(state),
      handleMenuClick,
      handleLoginOut
    }
  }
})
